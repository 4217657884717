@font-face {
  font-family: 'SansSans';
  src: url("fonts/EditionInternational-70.otf") format("woff");
  letter-spacing: .08em;
}
@font-face {
  font-family: 'Serif';
  src: url("fonts/Affix-Medium.woff2") format("woff2");
  letter-spacing: -0.3em;
  font-weight: 400;
}
body {
  margin: 0;
  cursor: url('./assets/cursor.svg'), pointer !important;
  background-color: white;
  color: #191919;
  font-family: 'SansSans';
  font-size: 21px;
  overflow: hidden;
  line-height: 1.35em;
  letter-spacing: .015em !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.Gray{
  color: #898989;
}
.Gray-disabled{
  color: #c9c9c9;
}
*{
  cursor: url('./assets/cursor.svg'), pointer !important;
}
.text{
  max-width: 500px !important;
  display: block;
  font-family: 'SansSans';
  color: #393939;
  font-size: 18px;
  line-height: 1.35em;
  letter-spacing: .033em !important;
}
.images-box{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
  height: 80dvh;
  width: 620px;
  overflow: hidden;
}
.images-box img{
  width: auto;
  height: auto;
}
.link-intext:hover{
  color: #191919;
  cursor: url('./assets/cursor.svg'), pointer !important;
}
.link-intext{
  text-decoration: none;
  color: #979595;
  transition: 300ms ease;
  text-decoration: underline;
  text-underline-offset: 4px;
}
.link{
  text-decoration: none;
  color: #979595;
  transition: 300ms ease;
}
::selection {
  color: #0B9E9E;
  background-color: transparent;
}
.nobr{
  text-wrap: nowrap;
}
.link:hover{
  color: #191919;
  cursor: url('./assets/cursor.svg'), pointer !important;
}
.images-small{
  max-height: 300px;
  width: auto;
}
.images{
  height: 57dvh;
  width: auto;
  border-radius: 2px;
}
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  padding: 0px 15px;
}
.top-container{
  /* font-family: 'Sans'; */
  padding: 12px;
  gap: 10px;
  bottom: 0px;
  position: fixed;
  width: 24%;
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  transition: width 500ms ease;
}
.quote{
  text-align: center;
}
.bottom-container, .top-container{
  width: calc(100% - 30px);
  justify-content: center;
  gap: 200px;
}
.bottom-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-block{
  padding: 18px;
  top: 0px;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SmallText{
  font-size: 14px;
}
.Logo{
  height: 18px;
  margin-top: 3px;
  fill: #979595bf;
  /* margin-right: 50px; */
}
.LogoTop{
  height: 18px;
  opacity: 0;
  margin-top: 3px;
  fill: #979595bf;
  transition: 500ms ease;
  /* margin-right: 50px; */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media (max-width: 1650px) {
  .bottom-container{
    width: 45%;
  }
}
@media (max-width: 1000px) {
  .text{
    max-width: 500px !important;
    display: block;
    font-family: 'SansSans';
    color: #393939;
    font-size: 0.92em;
    line-height: 126%;
    letter-spacing: .004em;
  }
  .images{
    height: auto;
    width: 92dvw;
  }
  .bottom-container, .top-container{
    width: calc(100% - 30px);
    justify-content: center;
  }
  /* .LogoTop{
    opacity: ;
  } */
  .Logo{
    height: 17px;
    margin-top: 1px; 
    display: none;
  }
  .SmallText{
    font-size: 13px;
  }
  .Gray-disabled{
    display: none;
  }
  body {
    font-size: 17px;
  }
}
@media (max-width: 700px) {
  .images-box{
    width: 100dvw;
  }
  .text{
    max-width: 300px !important;
  }
}